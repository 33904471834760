<template>
  <div class="page-rooter-fff">
    <nav-bar headerTitle="授权协议" :showBack="true" :header-back="appBack"></nav-bar>
    <app-page>
      <h2>{{ title }}</h2>
      <div class="content" v-html="content"></div>
    </app-page>

  </div>
</template>

<script>
import appMixin from '@/mixin/appMixin'
import {hideAppBackBtn, appBack} from "@/lib/appMethod";
import navBar from '@/components/nav-bar/nav-bar'
import appPage from '@/common/components/appPage'


export default {
  data() {
    return {
      title: '',
      content: ''
    };
  },
  mixins: [appMixin],
  components: {
    navBar,
    appPage,
  },
  created() {
    hideAppBackBtn()
    this.getInfo()

  },
  methods: {
    getInfo() {
      this.$axios.post(this.baseURLApp + '/user/getConfiguration', {key: 'portraitLicense'}).then(res => {
        this.content = res.data.text.replace(/\r\n/g, '<br>')
        this.title = res.data.title.replace(/\s+/g, '&nbsp;')
      })
    },
    appBack() {
      appBack();
    },
  },
};
</script>

<style lang="less" scoped>
  .page-rooter-fff {
    padding: 32px 0;
    h2 {
      width: 100%;
      text-align: center;
      margin: 0 0 56px;
    }
    .content {
      box-sizing: border-box;
      width: 100%;
      padding: 0 32px;
      font-size: 32px;
    }
  }
</style>
